.icon-span {
	margin-top: 20px;
	font-size: 30px;
	text-align: center;
	height: 70px;
	width: 70px;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 100%;
	float: left;
	line-height: 60px;
}
.info-box {
	padding: 10px 10px 10px 0;
	margin-left: 90px;
}
