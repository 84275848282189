.test::before {
	content: '\e601';
}
.hamburger {
	cursor: pointer;
	opacity: 0.7;
	transition: all 0.4s ease-out;
}
.hamburger:hover {
	opacity: 1;
}
