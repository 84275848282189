.modal{
    display: none;
    position:fixed;
    z-index: 1;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.892);
    background-color: rgba(0, 0, 0, 0.809);
}
.close{
    position:absolute;
    top: 85px;
    right: 45px;
    color: #f1f1f1;
    font-size: 60px;
    font-weight: bold;
    transition: 0.3s;
    cursor:pointer;
}
.modal-content{
    display:block;
    width: 40%;
    background-color: white;
}