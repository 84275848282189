.rotate {
	animation: rotation 2s infinite linear;
}
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
	height: 250px;
}
.color {
	border: 1px solid white;
	padding: 8px;
	width: 32px;
	height: 32px;
	margin: 5px;
	flex: 1 1 25%;
	display: flex;
	border-radius: 50px;
}
